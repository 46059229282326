import { AboutMeTitle, AboutMeDesc, AboutContainer } from "./AboutMe.styles";

const AboutMe = () => {
  return (
    <AboutContainer id="AboutMe">
      <div style={{ alignSelf: "center" }}>
        <AboutMeTitle>¡Hola! Soy Itza. Soy developer</AboutMeTitle>
        <AboutMeDesc>
          Me encanta trabajar con personas, resolver problemas y crear software
          asombroso.
        </AboutMeDesc>
      </div>
      <img
        style={{ width: "100%" }}
        src={`${process.env.PUBLIC_URL}/images/itza-blog.png`}
      ></img>
    </AboutContainer>
  );
};

export default AboutMe;
