import {
  ContactContainer,
  ContactTitle,
  ContactDesc,
  ContactSocialNetwork,
} from "./Contact.styles";
import ShapeDivider from "./ShapeDivider";
import SocialNetworkLogo from "../../SocialNetworkLogo/SocialNetworkLogo";

const Contact = () => {
  return (
    <ContactContainer id="Contact">
      <ShapeDivider />
      <ContactTitle>¿Quieres platicar más?</ContactTitle>
      <ContactDesc>
        Contáctame por cualquiera de estos medios y nos tomamos un café.{" "}
      </ContactDesc>
      <ContactDesc>
        Puedo hablar por horas sobre software, comida y perritos.{" "}
      </ContactDesc>
      <ContactSocialNetwork>
        <SocialNetworkLogo />
      </ContactSocialNetwork>
    </ContactContainer>
  );
};

export default Contact;
