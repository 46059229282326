import { MyServiceContainer, GridContainer } from "./MyServices.styles";
import { CommonsTitle } from "./../../commons/Commons.styles";
import ServiceCard from "../../ServiceCard/ServiceCard";
import { dataServices } from "../../../constants/services.constants";

const MyServices = () => {
  return (
    <MyServiceContainer>
      <CommonsTitle>
        ¿Cómo te puedo <strong>ayudar</strong>?
      </CommonsTitle>
      <GridContainer>
        {dataServices.map((service, index) => {
          return <ServiceCard key={index} {...service} />;
        })}
      </GridContainer>
    </MyServiceContainer>
  );
};

export default MyServices;
