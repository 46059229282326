import { dataSocial } from "../../constants/social.constants";
import {
  SocialNetworkLink,
  SocialNetworkImage,
} from "../sections/Contact/Contact.styles";

const SocialNetworkLogo = () => {
  return dataSocial.map((socialNetwork, index) => {
    const { name, url, imageSrc } = socialNetwork;
    return (
      <SocialNetworkLink href={url} target="_blank" rel="noreferrer">
        <SocialNetworkImage src={imageSrc} alt={name} />
      </SocialNetworkLink>
    );
  });
};

export default SocialNetworkLogo;
