import styled from "styled-components";

export const ContactContainer = styled.div`
  background: #292134;
  padding: 0px 0px 40px 0px;
`;

export const ContactTitle = styled.h3`
  font-weight: bold;
  font-size: 2rem;
  line-height: 5.143rem;

  color: #ffffff;

  margin-bottom: 40px;
  margin-top: 0px;
  text-align: center;
`;

export const ContactDesc = styled.h4`
  font-weight: 300;
  font-size: 1.286rem;
  line-height: 1rem;

  color: #ffffff;

  margin-bottom: 40px;
  text-align: center;
`;

export const ContactSocialNetwork = styled.div`
  display: flex;
  justify-content: center;
`;

export const SocialNetworkLink = styled.a`
  margin-right: 15px;
  margin-left: 15px;
  transition: transform 0.4s ease-out;
  :hover {
    transform: scale(1.3);
  }
`;

export const SocialNetworkImage = styled.img`
  height: 48px;
`;
