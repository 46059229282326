import styled from "styled-components";

export const TagContainer = styled.div`
  background: #f8eceb;
  font-size: 1rem;
  color: #231760;

  border-radius: 5px 5px 5px 5px;
  display: inline-block;
  margin: 0 10px 10px 0;
  padding: 0 10px 0 10px;
`;
