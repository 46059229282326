import { useEffect, useState } from "react";
export const useResponsiveWidth = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const setInnerWidth = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", setInnerWidth);
    return () => window.removeEventListener("resize", setInnerWidth);
  }, []);

  return width;
};
