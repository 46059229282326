import YouTubeEmbed from "./../../../components/YouTubeEmbed/YouTubeEmbed";
import { MyTalksContainer } from "./MyTalks.styles";
import { CommonsTitle } from "./../../commons/Commons.styles";
import { Carousel } from "react-responsive-carousel";
import { dataTalks } from "../../../constants/talks";
import { carouselProps } from "../../../constants/carousel.constants";
import { getSlidePercentageOfWidth } from "../../../utils/carousel.utils";
import { useResponsiveWidth } from "../../../hooks/useResponsiveWidth.hook";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const MyTalks = () => {
  const width = useResponsiveWidth();

  return (
    <MyTalksContainer>
      <CommonsTitle>
        <strong>Charlas</strong> y otros proyectos
      </CommonsTitle>

      <Carousel
        centerMode
        {...carouselProps}
        centerSlidePercentage={getSlidePercentageOfWidth(width)}
      >
        {dataTalks.map((talks) => {
          return (
            <YouTubeEmbed
              onClick={() =>
                window.open(talks.url, "_blank", "noopener,noreferrer")
              }
              key={talks.title}
              {...talks}
            />
          );
        })}
      </Carousel>
    </MyTalksContainer>
  );
};

export default MyTalks;
