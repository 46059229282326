import { PopupButton } from "react-calendly";

const CalendlyButton = (props) => {
  const { calendlyUrl, textButton } = props;
  return (
    <div className="App">
      <PopupButton
        url={calendlyUrl}
        rootElement={document.getElementById("root")}
        text={textButton}
      />
    </div>
  );
};

export default CalendlyButton;
