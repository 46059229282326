import AboutMe from "../components/sections/AboutMe/AboutMe";
import MyServices from "../components/sections/MyServices/MyServices";
import MyThoughts from "../components/sections/MyThoughts/MyThoughts";
import MyTalks from "../components/sections/MyTalks/MyTalks";
import Contact from "../components/sections/Contact/Contact";

const Homepage = () => {
  return (
    <>
      <AboutMe />
      <MyServices />
      <MyThoughts />
      <MyTalks />
      <Contact />
    </>
  );
};

export default Homepage;
