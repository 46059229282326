export const dataServices = [
  {
    highlighted: "Mentorías",
    title: "en tech",
    subtitle:
      "Crecí gracias a las enseñanzas de excelentes mentores. Por eso, comprendo su importancia y ¡me encantaría ser tu mentora!",
    textButton: "Agenda una mentoría gratuita	→",
    calendlyUrl: "https://calendly.com/itzareyesmx/free-mentoring",
  },
  {
    highlighted: "Trainings",
    title: "técnicos",
    subtitle:
      "Me encanta compartir lo que sé y puedo guiarte a través de trainings sobre agilidad, management, code, buenas prácticas de software y más.",
    textButton: "Agenda una sesión →",
    calendlyUrl:
      "https://calendly.com/itzareyesmx/trainings-conferences-and-software-development",
  },
];
