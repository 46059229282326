import Iframe from "react-iframe-click";

const YouTubeEmbed = (props) => {
  const { url, title, onClick } = props;
  return (
    <Iframe
      width="420"
      height="315"
      src={url}
      title={title}
      onInferredClick={onClick}
    />
  );
};

export default YouTubeEmbed;
