export const dataThoughts = [
  {
    imagePath: "/images/debtcost.png",
    tags: ["Software Development", "Technical Debt"],
    title: "La deuda técnica cuesta $1,52 trillones anuales",
    desc:
      "Si por si a caso faltaran argumentos para poner foco en la calidad de software, les cuento sobre los costos.",
    externalUrl:
      "https://medium.com/@itzareyesmx/la-deuda-t%C3%A9cnica-cuesta-1-52-trillones-anuales-1ead73736a46",
  },
  {
    imagePath: "/images/gameoflife.png",
    tags: ["Software Development", "Code"],
    title: "Lo que me enseñó “El juego de la vida” de Conway",
    desc:
      "Una vez que lo leí terminé fascinada por cómo, bajo tan pocas y tan sencillas reglas, se pueden tener comportamientos de complejidad grande.",
    externalUrl:
      "https://medium.com/@itzareyesmx/lo-que-me-ense%C3%B1%C3%B3-el-juego-de-la-vida-de-conway-7654bd8fef38",
  },
  {
    imagePath: "/images/lean-coffee-template.png",
    tags: ["Engineering Management", "Agile"],
    title: "Lean Coffee + Mini training (Descargable)",
    desc:
      "Una de las formas de mantener vivo el Lean Coffee es rotando a los facilitadores, así que para preprararlos les imparto este mini trainning en unos 15 o 20 mins y con esto están listos para facilitar la sesión.",
    externalUrl:
      "https://itzareyesmx.medium.com/lean-coffee-mini-training-descargable-5ff19c36bc83",
  },
  {
    imagePath: "/images/lean-coffee.png",
    tags: ["Engineering Management", "Agile"],
    title: "Lean Coffee, 35 tazas de café después",
    desc:
      "No es la primera vez que organizo un Lean Coffee, en todas mis experiencias siempre me han traído buenos resultados. Tengo datos que lo avalan y te los quiero compartir.",
    externalUrl:
      "https://itzareyesmx.medium.com/lean-coffee-35-tazas-de-caf%C3%A9-despu%C3%A9s-e8a2ea33b8b9",
  },
  {
    imagePath: "/images/sg4w.png",
    tags: ["Pensamientos", "Mujeres En Tecnología", "Sgwomen"],
    title: "¡Obtuve el reconocimiento Líder Emergente de SG4W!",
    desc:
      "Enterarme que estaba nominada como Líder Emergente en SG y después descubrir que había ganado me hizo muy feliz. Les cuento mi experiencia.",
    externalUrl:
      "https://itzareyesmx.medium.com/obtuve-el-reconocimiento-l%C3%ADder-emergente-de-sg4w-d2fb97addcfd",
  },
  {
    imagePath: "/images/c4.png",
    tags: ["Arquitectura De Software", "Documentation", "Diagrams"],
    title: "C4 Model. Mi experiencia + Ejemplo",
    desc:
      "Siempre he buscado la forma de poder comunicar y visualizar las arquitecturas de una forma clara para todos, entonces encontré C4Model.",
    externalUrl:
      "https://itzareyesmx.medium.com/c4-model-mi-experiencia-usandolo-248aea8e380d",
  },
  {
    imagePath: "/images/organizada.png",
    tags: ["Pensamientos", "Administración Del Tiempo"],
    title: "Eres muy organizada ¿cómo le haces?",
    desc:
      "Me puse a reflexionar en qué es lo que hago para lograr organizarme exitosamente y no dudé en compartirlo con ustedes.",
    externalUrl:
      "https://itzareyesmx.medium.com/eres-muy-organizada-c%C3%B3mo-le-haces-d39967ccd1fc",
  },
  {
    imagePath: "/images/blog.png",
    tags: ["Pensamientos", "Motivación", "Constancia"],
    title: "¿Por qué me animé a escribir un blog?",
    desc:
      "Escribir un blog es un deseo que he tenido desde hace mucho tiempo, pero me habían detenido varias circunstancias, te cuento cómo animé.",
    externalUrl:
      "https://itzareyesmx.medium.com/por-qu%C3%A9-me-anim%C3%A9-a-escribir-un-blog-d45dd2a3981",
  },
];
