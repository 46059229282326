import { MyThoughtsContainer } from "./MyThoughts.styles";
import { CommonsTitle } from "./../../commons/Commons.styles";
import BlogCard from "../../BlogCard/BlogCard";
import { Carousel } from "react-responsive-carousel";
import { carouselProps } from "../../../constants/carousel.constants";
import { getSlidePercentageOfWidth } from "../../../utils/carousel.utils";
import { useResponsiveWidth } from "../../../hooks/useResponsiveWidth.hook";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { dataThoughts } from "../../../constants/thoughts.constants";

const MyThoughts = () => {
  const width = useResponsiveWidth();

  return (
    <MyThoughtsContainer>
      <CommonsTitle>
        Pensamientos sobre <strong>software</strong> y{" "}
        <strong>otros temas</strong>
      </CommonsTitle>

      <Carousel
        centerMode
        interval={4000}
        {...carouselProps}
        centerSlidePercentage={getSlidePercentageOfWidth(width)}
      >
        {dataThoughts.map((thought, index) => {
          return <BlogCard key={index} {...thought} />;
        })}
      </Carousel>
    </MyThoughtsContainer>
  );
};

export default MyThoughts;
