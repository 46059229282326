import styled from "styled-components";

export const BlogCardContainer = styled.div`
  background: #ffffff;
  border: 1px solid #ededf0;
  border-radius: 10px;
  margin: 20px 20px 5px 5px;
  height: 97%;
`;

export const BlogCardInfo = styled.div`
  background: #ffffff;

  padding: 30px 46px;

  color: #231760;
  text-align: left;
`;

export const BlogCardTitle = styled.p`
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2.357rem;
  margin: 0px;
`;

export const BlogCardDesc = styled.p`
  font-size: 1.286rem;
  line-height: 2.357rem;

  color: #6d669e;
`;

export const BlogImageContainer = styled.div`
  background: #f8eceb;
  padding: 30px 30px;
`;
