import {
  ContainerServiceCard,
  CardTitle,
  CardSubtitle,
  CardHighlightedText,
  CardButton,
} from "./ServiceCard.styles";
import CalendlyButton from "../CalendlyButton/CalendlyButton";

const ServiceCard = (props) => {
  const { title, highlighted, subtitle } = props;
  return (
    <ContainerServiceCard>
      <div>
        {" "}
        <CardTitle>
          <CardHighlightedText>{highlighted}</CardHighlightedText> {title}
        </CardTitle>
      </div>
      <CardSubtitle>{subtitle}</CardSubtitle>
      <CardButton>
        <CalendlyButton {...props}></CalendlyButton>
      </CardButton>
    </ContainerServiceCard>
  );
};

export default ServiceCard;
