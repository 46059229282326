export const dataTalks = [
  {
    title:
      "¿Y si probamos? ¿Qué tipo de pruebas debería de agregar a mi código?",
    url: "https://www.youtube.com/embed/ehtQ-2ASI14",
    event: "Software Gurú - Dev Day 4 Woman",
  },
  {
    title: "¿Cómo sobrevivir a la deuda técnica?",
    url: "https://www.youtube.com/embed/0mvHaXIikqs",
    event: "Agilidad de Devs para Devs",
  },
  {
    title: "Indigo - Best Place to Code",
    url: "https://www.youtube.com/embed/NXjp8ih-51A",
    event: "Software Gurú - Best Place to Code",
  },
];
