import Homepage from './pages/home'
import './App.css'

function App() {
  return (
    <div className="App">
        <Homepage></Homepage>
    </div>
  );
}

export default App;
