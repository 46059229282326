import styled from "styled-components";

export const MyServiceContainer = styled.div`
  background: #f8eceb;
  padding: 60px 10%;
  text-align: center;
`;

export const GridContainer = styled.div`
  max-width: 100vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 100px;
  grid-row-gap: 30px;

  @media (max-width: 1280px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
