import {
  BlogCardContainer,
  BlogCardInfo,
  BlogCardTitle,
  BlogCardDesc,
  BlogImageContainer,
} from "./BlogCard.styles";
import Tag from "../Tag/Tag";

const BlogCard = (props) => {
  const { imagePath, tags, title, desc, externalUrl } = props;
  return (
    <BlogCardContainer
      onClick={() => window.open(externalUrl, "_blank", "noopener,noreferrer")}
    >
      <BlogImageContainer>
        <img src={`${process.env.PUBLIC_URL + imagePath}`}></img>
      </BlogImageContainer>
      <BlogCardInfo>
        {tags.map((tag, index) => {
          return <Tag tag={tag} key={index} />;
        })}
        <BlogCardTitle>{title}</BlogCardTitle>
        <BlogCardDesc>{desc}</BlogCardDesc>
      </BlogCardInfo>
    </BlogCardContainer>
  );
};

export default BlogCard;
