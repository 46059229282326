import styled from "styled-components";

export const AboutMeTitle = styled.h1`
  margin: 0;

  font-family: "Hind", sans-serif;
  font-weight: 500;
  font-size: 5.75rem;
  line-height: 6rem;

  color: #ffffff;
`;

export const AboutMeDesc = styled.h3`
  font-weight: 300;
  font-size: 24px;
  line-height: 1.5;

  color: #ffffff;

  margin-top: 45px;
  margin-bottom: 0;
`;

export const AboutContainer = styled.div`
  background: #fd5057;
  padding: 260px 7% 260px 7.5%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8%;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    img {
      display: none;
    }
  }
`;
