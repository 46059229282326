import styled from "styled-components";

export const ContainerServiceCard = styled.div`
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 28px 64px rgb(31 45 39 / 16%);
  padding: 30px 46px;
`;

export const CardTitle = styled.p`
  font-weight: bold;
  font-size: 1.65rem;
  line-height: 2.357rem;
  margin: 0px;

  color: #231760;
`;

export const CardSubtitle = styled.p`
  font-size: 1.286rem;
  line-height: 2.357rem;

  color: #231760;
`;

export const CardHighlightedText = styled.strong`
  color: #f29bab;
`;

export const CardButton = styled.div`
  button {
    font-size: 1.2rem;
    font-weight: bold;
    font-family: "Poppins", sans-serif;
    color: #f29bab;

    padding: 2px 24px;
    height: 48px;
    background: #fff;

    border-style: none;
    border: 0px;

    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  }
`;
