export const dataSocial = [
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/in/itzareyesmx/",
    imageSrc: "/images/linkedin.png",
  },
  {
    name: "Twitter",
    url: "https://twitter.com/itzareyesMX",
    imageSrc: "/images/twitter.png",
  },
  {
    name: "Medium",
    url: "https://medium.com/@itzareyesmx",
    imageSrc: "/images/medium.png",
  },
  {
    name: "Calendly",
    url: "https://calendly.com/itzareyesmx",
    imageSrc: "/images/calendar.png",
  },
];
