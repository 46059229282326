import styled from "styled-components";

export const CommonsTitle = styled.h3`
  font-weight: 300;
  font-size: 2rem;

  color: #1f1659;

  margin-bottom: 40px;
  text-align: center;
`;
